import React from 'react'

export default function Refund_policy() {
  return (
    <div class="bg-gray-100 font-sans leading-normal tracking-normal">
    <div class="container mx-auto py-12 px-6">
        <h1 class="text-3xl font-bold text-gray-800 mb-6">Shipping Policy</h1>
        <p class="mb-4 text-gray-700">
            Thank you for choosing Hindustan Taxi Service. We are committed to providing reliable and timely transportation services. Please review our shipping policy, which explains how we handle the dispatch and delivery of any physical items or documents related to your bookings.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Shipping of Documents and Items</h2>
        <p class="mb-4 text-gray-700">
            If any physical documents, receipts, or items related to your booking need to be shipped, we will use standard shipping services. All shipments will be dispatched to the address provided at the time of booking. Please ensure that your address is accurate and up-to-date.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Processing Time</h2>
        <p class="mb-4 text-gray-700">
            Orders for any physical items, such as booking confirmations or receipts, will be processed within 1-2 business days after payment is confirmed. You will receive a notification once your order has been dispatched.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Delivery Time</h2>
        <p class="mb-4 text-gray-700">
            Delivery times vary depending on your location and the shipping method selected. On average, deliveries within India take 5-7 business days. International deliveries may take longer, depending on customs and local postal services.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Shipping Charges</h2>
        <p class="mb-4 text-gray-700">
            Shipping charges, if applicable, will be calculated based on the destination and weight of the package. The total shipping cost will be displayed at checkout.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Tracking Information</h2>
        <p class="mb-4 text-gray-700">
            Once your order is dispatched, you will receive a tracking number via email to monitor the status of your delivery. Please allow up to 24 hours for the tracking information to be updated.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Lost or Damaged Shipments</h2>
        <p class="mb-4 text-gray-700">
            Hindustan Taxi Service is not responsible for any lost or damaged shipments once they have been handed over to the shipping carrier. If your shipment is lost or damaged, please contact the carrier directly using the tracking information provided.
        </p>

        <h2 class="text-2xl font-semibold text-gray-800 mt-8 mb-4">Contact Us</h2>
        <p class="mb-4 text-gray-700">
            If you have any questions or concerns regarding our shipping policy, please contact us:
        </p>
        <ul class="list-disc pl-6 text-gray-700">
            <li class="mb-2 text-black-500 font-bold">Phone: 
                <a href="tel:+917600060604" class="text-black-500 "> +91 76000 60604 </a></li>
            <li class="mb-2 text-black-500 font-bold">Email: 
                <a href="mailto:support@hindustantaxiservice.com" class="text-black-500 "> support@hindustantaxiservice.com </a></li>
        </ul>
    </div>
</div>
  )
}
