import React, { useState, useEffect } from 'react';
import OneWayPage from './OneWayPage';
import RoundTripPage from './RoundTripPage';
import HourlyRent from './HourlyRent';
import AirportPage from './form_to_AirportPage';
import TripImage from './TripImage';
import OurServices from './OurServices';
import DescriptionPage from './DescriptionPage';
import Testimonial from '../layouts/testimonial';
import Memories from '../layouts/Memories';
import Footer from '../layouts/footer';

const BookingForm = () => {
  const [tripType, setTripType] = useState('ONE WAY'); 

  const handleTripTypeChange = (type) => {
    setTripType(type);
  };

  return (<>
    <div className="bg-cover bg-center bg-dunes h-[250px]">
      <div className="flex flex-col items-center justify-center p-4">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-[41px] font-bold text-white text-center mt-8 font-headingFont">
          ENJOY EASY TRAVEL WITH CLASSIC HINDUSTAN TAXIS.
        </h1>

        <div className="bg-white max-w-[1200px] w-full shadow-lg p-4 mt-16 rounded-[16px] relative z-10 transition-all duration-300 ease-in-out transform  ">
  <div className="flex justify-center mb-4">
    <div className="grid grid-cols-2 gap-3 sm:grid-cols-4">
      {['ONE WAY', 'ROUND TRIP', 'LOCAL', 'AIRPORT'].map((type) => (
        <button
          key={type}
          className={`py-3 px-4 rounded-lg font-semibold text-[13px] transition-all duration-300 ease-in-out transform ${
            tripType === type
              ? 'bg-customBlue text-white shadow-lg scale-105'
              : 'bg-white text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-black hover:shadow-md'
          }`}
          onClick={() => handleTripTypeChange(type)} >
          {type}
        </button>
      ))}
    </div>
  </div>
  <div className="w-full mt-4">
    {tripType === 'ONE WAY' && <OneWayPage />}
    {tripType === 'ROUND TRIP' && <RoundTripPage />}
    {tripType === 'LOCAL' && <HourlyRent />}
    {tripType === 'AIRPORT' && <AirportPage />}
  </div>

  {/* Additional components can be added here */}
  {/* <TripImage />
  <OurServices />
  <DescriptionPage />
  <Testimonial />
  <Memories /> */}
</div>



      </div>
    </div>


  </>
  );
};

export default BookingForm;
