import React from 'react'
import { FaLocationArrow, FaMailBulk, FaPhone, FaVoicemail } from 'react-icons/fa'

export default function ContactUs() {
  return (<>
  
  <div className="bg-gradient-to-r from-blue-50 via-white to-blue-50 font-sans leading-normal tracking-normal">
      <div className="max-w-6xl mx-auto py-12 px-6">

        <h1 className="text-5xl font-bold text-customBlue text-center mb-10">Get In Touch</h1>
        <p className="text-lg text-gray-700 text-center mb-12">
          We're here to assist you with any inquiries or concerns. Reach out to us via phone, email, or simply fill out the contact form below!
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div className="mb-12">
            <div className="bg-white p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg text-center">
              <div className="mb-4">
                <div className="flex justify-center items-center">
                  <FaLocationArrow className="text-customBlue" size={35} />
                </div>
              </div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">Our Office</h2>
              <p className="text-gray-600 font-[500] text-md">
                Hindustan Taxi Service Pvt. Ltd.<br />
                F-22, Swastik Plaza, Yogichowk,<br />
                Surat, Gujarat - 395010
              </p>
            </div>

            <div className="bg-white p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg text-center mt-4">
              <div className="mb-4">
                <div className="flex justify-center items-center">
                  <FaPhone className="text-customBlue" size={35} />
                </div>
              </div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">Phone</h2>
              <div className="flex gap-2 justify-center text-gray-600 font-[500] text-md">
                <p><a href="tel:+917600060604">+91 76000 60604</a></p>
                <p>|</p>
                <p><a href="tel:+919925403831">+91 99254 03831</a></p>
              </div>
            </div>

            <div className="bg-white p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg text-center mt-4">
              <div className="mb-4">
                <div className="flex justify-center items-center">
                  <FaMailBulk className="text-customBlue" size={35} />
                </div>
              </div>
              <h2 className="text-xl font-semibold text-gray-800 mb-2">Email</h2>
              <p className='text-gray-600 font-[500] text-md'><a href="mailto:support@hindustantaxiservice.com">support@hindustantaxiservice.com</a></p>
            </div>
          </div>

          <div className="col-span-1 md:col-span-2 bg-white p-5 shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded-lg mb-12">
            <form className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-gray-700 font-medium">Your Name</label>
                <input type="text" id="name" name="name" required className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none " />
              </div>

              <div>
                <label htmlFor="email" className="block text-gray-700 font-medium">Your Email</label>
                <input type="email" id="email" name="email" required className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none" />
              </div>

              <div>
                <label htmlFor="subject" className="block text-gray-700 font-medium">Subject</label>
                <input type="text" id="subject" name="subject" required className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none" />
              </div>

              <div>
                <label htmlFor="message" className="block text-gray-700 font-medium">Message</label>
                <textarea id="message" name="message" rows="4" required className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none"></textarea>
              </div>

              <div className="text-center">
                <button type="submit" className="px-10 py-2 bg-customBlue text-white font-semibold rounded-md shadow">
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="mt-12">
          <h2 className="text-3xl font-bold text-black my-8 text-center">Find Us Here For Location</h2>
          <div className="flex justify-center w-full shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] rounded-xl ">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.4201837685277!2d72.8855718743116!3d21.215180281351362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f7a8c40303d%3A0x4ca46c21d5592308!2sSwastik%20Plaza%2C%20Puna-Simada%20Rd%2C%20Yogi%20Chowk%20Ground%2C%20Chikuwadi%2C%20Nana%20Varachha%2C%20Surat%2C%20Gujarat%20395006!5e0!3m2!1sen!2sin!4v1726919241184!5m2!1sen!2sin" width="1500" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='rounded-xl'></iframe>
          </div>
        </div>
      </div>
    </div>

  </>
  )
}
