// Footer.jsx
import React from 'react';
import { FaFacebook, FaInstagram, FaPhone, FaWhatsapp } from 'react-icons/fa';
import { FaLocationArrow } from 'react-icons/fa';
import { FaAddressBook } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 bottom-0">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 text-left">
          <div>
            <h1 className="text-md font-bold text-customBlue"> COMPANY </h1>
            {/* <p className="mt-2">About Us</p> */}
            <p className="mt-2 text-sm"> <a href='/blogs' className='text-white'> Blogs </a> </p>
            <p className="mt-2 text-sm" > <a href='/privacy-policy' className='text-white'> Privacy Policy </a></p>
            <p className="mt-2 text-sm" > <a href='/refund-policy' className='text-white'> Refund Policy </a></p>
            <p className="mt-2 text-sm" > <a href='/shipping-policy' className='text-white'> Shipping Policy </a></p>
            <p className="mt-2 text-sm" > <a href='/terms-condition' className='text-white'> Terms & Conditions </a></p>
            <p className="mt-2 text-sm" > <a href='/contactus' className='text-white'> Contact Us </a></p>


            <p className="mt-2 text-sm"> Download App </p>

            {/* <p className="mt-2">Terms & Conditions</p> */}
            {/* <p className="mt-2">Refunds</p> */}
          </div>
          <div>
            <h1 className="text-lg font-bold text-customBlue"> SERVICES </h1>
            <p className="mt-2 text-sm"> <a href='/' className='text-white'> Local Car Rentals </a> </p>
            <p className="mt-2 text-sm"> <a href='/' className='text-white'> Outstation Taxi </a> </p>
            <p className="mt-2 text-sm"> <a href='/' className='text-white'> One Way Cabs </a> </p>
            <p className="mt-2 text-sm"><a href='/' className='text-white'> Airport Taxi </a> </p>
          </div>
          <div>
            <h1 className="text-lg font-bold text-customBlue"> GET IN TOUCH </h1>
            <a href="tel:+917600060604" className="mt-2 text-left flex items-center text-white">
              <FaPhone size={18} className="mr-4" /> +91 76000 60604 
            </a>
            <p className="mt-2 text-sm text-left flex">
              <FaAddressBook size={18} className='mr-4' /> contact@hindustantaxiservice.com</p>
            <p className="mt-2 text-sm text-left flex">
              <FaLocationArrow size={18} className='mr-4' /> F-22, Swastik Plaza, Yogichowk, Surat, Gujarat - 395010</p>
            <div className="flex gap-5 my-4 ml-1">
              <a href='https://www.instagram.com/hindustan_taxi_service/' target='_blank'> <div className='bg-customBlue hover:bg-white p-1 rounded-md cursor-pointer'>
                <FaInstagram size={22} className='text-black ' />
              </div></a>
              {/* <div className='bg-customBlue hover:bg-white p-1 rounded-md cursor-pointer'>
                <FaFacebook size={22} className='text-black ' />
              </div> */}
              <a href='https://api.whatsapp.com/send?phone=917600060604' target='_blank'> <div className='bg-customBlue hover:bg-white p-1 rounded-md cursor-pointer'>
                <FaWhatsapp size={22} className='text-black ' />
              </div></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
