import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/layouts/header';
import Home from './components/layouts/home';
import './App.css';
import OneWayDetails from './components/pages/detailsPage/onewayDetails';
import RoundTripDetails from './components/pages/detailsPage/roundTripDetails';
import EightHoursDetails from './components/pages/detailsPage/hourlyRentDetails/eight_hours_details';
import TenHoursDetails from './components/pages/detailsPage/hourlyRentDetails/ten_Hours_details';
import AirportDetails from './components/pages/detailsPage/airportDetails';
import OneWay_car_details from './components/pages/Select_car_details/oneWay_car_details';
import TwelveHoursDetails from './components/pages/detailsPage/hourlyRentDetails/twelve_hours_details';
import Footer from './components/layouts/footer';
import Blog from './components/layouts/Blog';
import Testimonial from './components/layouts/testimonial_details';
import ContactUs from './components/layouts/contactUs';
import PrivacyPolicy from './components/layouts/privacy_Policy';
import DemoInvoice from './demo_invoice';
import RefundPolicy from './components/layouts/refund_policy';
import ShippingPolicy from './components/layouts/shipping_policy';
import Terms_conditions from './components/layouts/Terms & Conditions';



function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          
          <Route path='/' element={<Home />} />
          <Route path='/onewaydetails' element={<OneWayDetails />} />
          <Route path='/roundtripdetails' element={<RoundTripDetails />} />
          <Route path='/eightHrsdetails' element={<EightHoursDetails />} />
          <Route path='/tenHrsdetails' element={<TenHoursDetails />} />
          <Route path='/twelveHrsdetails' element={<TwelveHoursDetails />} />
          <Route path='/airportHrsdetails' element={<AirportDetails />} />
          <Route path='/car-booking/:id' element={<OneWay_car_details />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/testimonial' element={<Testimonial />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/demo' element={<DemoInvoice />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/shipping-policy' element={<ShippingPolicy />} />
          <Route path='/terms-condition' element={<Terms_conditions />} />
          <Route path='/contactus' element={<ContactUs />} />

        </Routes>
        {/* <ToastContainer closeButton={false} position="bottom-right" /> */}
        <Footer />
      </Router>
    </>
  );
}

export default App



// import React from 'react';
// import bg from './components/pages/detailsPage/bg.jpg';
// import img from './components/pages/detailsPage/imgpsh_fullsize_anim.png';

// const ImageOverlay = () => {
//   return (
//     <div className="relative h-screen w-screen">
//       <img
//         src={bg}
//         alt="Background"
//         className="absolute top-0 left-0 h-full w-full object-cover"
//       />
//       <div className="absolute inset-0 flex items-center justify-center">
//         <img
//           src={img}
//           alt="Overlay"
//           className="w-[360px] h-screen"
//         />
//       </div>
//     </div>
//   );
// };

// export default ImageOverlay;
